/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--left pb--50 pt--50" name={"o-mne"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-blend--05);\">BLUE BEAR</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Reklamní a marketingová společnost Blue Bear, od nápadu po realizaci.<br><br><br><br><span style=\"font-style: italic;\">„Jsem přesvědčen o tom, že marketing je tou nejmocnější silou, <br>kterou mají k dispozici lidé, kteří chtějí dosáhnout změny.“ &nbsp;<br><br>Seth Godin&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=860x_.png 860w, https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=1400x_.png 1400w, https://cdn.swbpg.com/t/26648/9fbcce636f9c47358e1aa330bea44bf7_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"298stjj2trb"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Reklamní plochy&nbsp;<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":564}} content={"Nabízíme velké množství outdorových reklamních ploch typu CLV, LCD umístěných na frekventovaných lokalitách po celé ČR.\nKompletní nabídku Vám rádi připravíme na základě Vašich požadavků jak na počet ploch, tak na jejich umístění.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/8eb3b2c0cd6c47dfb34bec41ac83f492_e=3x3x1077x1077_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":227}} srcSet={"https://cdn.swbpg.com/t/26648/8eb3b2c0cd6c47dfb34bec41ac83f492_e=3x3x1077x1077_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/8eb3b2c0cd6c47dfb34bec41ac83f492_e=3x3x1077x1077_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/8eb3b2c0cd6c47dfb34bec41ac83f492_e=3x3x1077x1077_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/89d79517c7f44638bbcc00371d2df0e7_e=0x0x1079x1079_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":227}} srcSet={"https://cdn.swbpg.com/t/26648/89d79517c7f44638bbcc00371d2df0e7_e=0x0x1079x1079_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/89d79517c7f44638bbcc00371d2df0e7_e=0x0x1079x1079_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/89d79517c7f44638bbcc00371d2df0e7_e=0x0x1079x1079_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Příprava POSM materiálů"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":565}} content={"Připravíme Vám marketingové materiály k použití v místě prodeje.\nMateriály nejen navrhneme, ale i vyrobíme a v rámci provozoven nainstalujeme. Pro tiskové materiály Vám rádi navrhneme systém distribuce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"w4j3xnkvauo"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Grafické a tiskové služby"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":561}} content={"Od jednoduchého letáku až k firemní identitě? S Blue Bear nezáleží na velikosti Vaší zakázky, jsme tu pro Vás, ať už potřebujete jednorázově pomoci, či zajistit kompletní servis<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/8cd57fe414304591af5dfc2bbc837fc6_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":227}} srcSet={"https://cdn.swbpg.com/t/26648/8cd57fe414304591af5dfc2bbc837fc6_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/8cd57fe414304591af5dfc2bbc837fc6_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/8cd57fe414304591af5dfc2bbc837fc6_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/f2a7b641deb449e7831f465612fc0e56_e=0x0x1080x1080_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":227}} srcSet={"https://cdn.swbpg.com/t/26648/f2a7b641deb449e7831f465612fc0e56_e=0x0x1080x1080_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/f2a7b641deb449e7831f465612fc0e56_e=0x0x1080x1080_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/f2a7b641deb449e7831f465612fc0e56_e=0x0x1080x1080_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Firemní dárky pro Vás i Vaše zákazníky"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":560}} content={"Blue Bear Vám zajistí originální dárky a propagační předměty, přesně dle současných trendů.\nRádi s Vámi probereme možnosti prezentace Vaší společnosti s přihlédnutím k Vašemu druhu podnikání"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"5q9joq1csem"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"Kontaktujte BLUE BEAR<br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center mt--16" content={"<span style=\"color: var(--color-custom-1);\">blue bear marketing agency s.r.o.</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--12" style={{"maxWidth":1000}} content={"Korunní 2569, Vinohrady \n101 00 Praha\n<br>+420 777 941 919\n<br>&nbsp;info@bluebearagency.cz<br>IČO: 071 64378 DIČ: CZ07164378<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right fs--12" action={"/contact"} fields={[{"name":"Společnost / jméno ","type":"text","required":true,"placeholder":""},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"77icusmd0j9"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/26648/08299682b5d548699d59e7e8af62af00_e=56x161x904x678_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/26648/08299682b5d548699d59e7e8af62af00_e=56x161x904x678_s=350x_.png 350w, https://cdn.swbpg.com/t/26648/08299682b5d548699d59e7e8af62af00_e=56x161x904x678_s=660x_.png 660w, https://cdn.swbpg.com/t/26648/08299682b5d548699d59e7e8af62af00_e=56x161x904x678_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box text-box--center pl--0" style={{"maxWidth":562}} content={"<a href=\"https://www.bluebearshop.cz\" target=\"_blank\">www.bluebearshop.cz<br>partnerský e-shop pro prodejny FMCG sortimentu</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}